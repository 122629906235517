












































import { API_USER_LIST, API_ACTIVITY_ASSIGN } from "@/configs/Apis";
import commonStore from "@/stores/modules/views/common";
import {
  isEmptyOrWhiteSpace,
  showError,
  showSuccess,
  showWarning,
  showConfirm,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue } from "vue-property-decorator";
import formatters from "@/utils/ColumnFormater";
@Component
export default class ActivityUserSelector extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  activityFilter!: any;

  businessUserQueryData: any = { showAll: false };
  businessUserQueryStr = JSON.stringify({
    userType: "INNER",
    disabled: false,
  });

  get colFormatters() {
    return formatters;
  }

  mounted() {
    this.businessUserQueryStr = JSON.stringify({
      userType: "INNER",
      disabled: false,
    });
    this.$store.unregisterModule("businessuser");
    this.$store.registerModule("businessuser", commonStore);
    const columns: Array<TableColumnOption> = [
      {
        prop: "loginName",
        display: "账号",
      },
      {
        prop: "name",
        display: "姓名",
      },
      {
        prop: "userType",
        display: "角色",
      },
      {
        prop: "email",
        display: "邮箱",
      },
      {
        prop: "phone",
        display: "电话",
      },
      {
        prop: "disabled",
        display: "状态",
      },
    ];
    this.applyColumnFormatters(columns);
    this.$store.dispatch("businessuser/setColumns", columns);
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      userType: this.colFormatters.formatUserLevel.bind(this),
      disabled: this.colFormatters.formatUserStatus.bind(this),
      phone: this.colFormatters.formatPhone.bind(this),
      email: this.colFormatters.formatEmail.bind(this),
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  get userDataApi() {
    return API_USER_LIST;
  }

  searchBusinessUser() {
    const queryData: any = this.businessUserQueryData;
    const params: any = { userType: "INNER", disabled: false };
    if (!isEmptyOrWhiteSpace(queryData.keyword)) {
      params.keyword = queryData.keyword;
    }
    if (queryData.showAll) {
      delete params.disabled;
    }
    this.businessUserQueryStr = JSON.stringify(params);
  }

  onAssignBusinessUser() {
    const usergrid = this.$refs.businessusergrid as any;
    const selectedUser = usergrid.getCurrentDataRow();
    if (!selectedUser) {
      showWarning("请选择用户");
      return;
    }
    const title = this.activityFilter.selectedIds && this.activityFilter.selectedIds.length > 0
      ? `选中的 ${this.activityFilter.selectedIds.length} 个时限任务`
      : "当前列表全部时限任务";
    showConfirm(`是否确认将 <font color="#F56C6C">${title}</font> 处理人 <font color="#67C23A">${selectedUser.name}</font>？`).then(() => {
      Ajax.saveData(
        API_ACTIVITY_ASSIGN,
        {
          toUserId: selectedUser.id,
          activityFilter: this.activityFilter
        },
        "POST"
      )
        .then(() => {
          showSuccess("操作完成");
          this.$emit("data-success");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
